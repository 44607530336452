import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDfYJh_-b3UqQElWnOtclq4lglcnkPxuMw",
  authDomain: "manaratalmusafir.firebaseapp.com",
  projectId: "manaratalmusafir",
  storageBucket: "manaratalmusafir.appspot.com",
  messagingSenderId: "180103182268",
  appId: "1:180103182268:web:34f540e0e35a318d6f9db9",
  measurementId: "G-NLKBVVNC1X"
};

firebase.initializeApp(firebaseConfig);
export default firebase;
